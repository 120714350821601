<template>
  <div class="lineCharts">
    <div ref="lineCharts" class="line"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {};
  },
  props: {
    ChartsData: {
      type: Object,
      require: true,
      default: null
    }
  },
  mounted() {
    if (this.ChartsData !== null) {
      this.init();
    }
  },
  watch: {
    ChartsData() {
      this.init();
    },
    deep: true
  },
  methods: {
    init() {
      let myChart = echarts.init(this.$refs.lineCharts);
      let option;

      option = {
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            if (params[0].seriesName === '次数') {
              return `${params[0].seriesName}<br/>${params[0].marker}${params[0].name}:${params[0].value}次`;
            } else {
              let min = `${(params[0].value % 60).toFixed(0)}`;
              let h = `${(params[0].value / 60).toFixed(0)}`;
              // str = str.concat(`${params[0].marker + params[0].seriesName}: ${h}小时${min}分钟<br/>`);
              return `${params[0].seriesName}<br/>${params[0].marker + params[0].seriesName}: ${h}小时${min}分钟<br/>`;
            }
          }
        },
        grid: {
          top: '3%',
          right: '3%',
          left: '3%',
          bottom: '20%'
        },
        legend: {
          data: this.ChartsData.title,
          bottom: '1%',
          left: '1%'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.ChartsData.xaxisValues,
          axisLabel: {
            interval: 0
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: this.ChartsData.title[0],
            type: 'line',
            data: this.ChartsData.yaxisValues,
            itemStyle: {
              color: this.ChartsData.color,
              borderColor: this.ChartsData.bordercolor
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
.lineCharts {
  height: 350px;
  position: relative;
}
.legend {
  position: absolute;
  bottom: 10%;
  left: 5%;
}
.legend::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 10px;
  background-color: #f69604;
}
.line {
  height: 100%;
}
</style>
