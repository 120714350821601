<template>
  <div class="OeeCharts">
    <el-row :gutter="10">
      <el-col :span="2" class="btns">
        <el-button class="iconfont icon-dcdaochu1" @click="exportToImg">导出</el-button>
      </el-col>
      <el-col :offset="2" :span="3">
        <el-select v-model="CompareOptionsValue" placeholder="类型选择" @change="CompareOptionsValueChange">
          <el-option v-for="item in CompareOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-cascader
          :disabled="CompareOptionsValue && CompareOptionsValue === '1'"
          :show-all-levels="false"
          clearable
          collapse-tags
          v-model="lineValue"
          placeholder="工厂结构"
          :props="Props"
          :value="lineValue"
          :options="lineTree"
          @change="LinehandleChange"
        ></el-cascader>
      </el-col>
      <el-col :span="3">
        <el-select :disabled="CompareOptionsValue && CompareOptionsValue !== '5'" multiple collapse-tags clearable @clear="clearDevice" @change="DeivcehandleChange" v-model="deviceId" placeholder="选择设备">
          <el-option v-for="item in DeviceOptions" :key="item.deviceId" :label="item.name" :value="item.deviceId"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select :disabled="CompareOptionsValue !== '1'" multiple collapse-tags clearable @clear="clearDeviceType" @change="DeivceTypehandleChange" v-model="deviceTypeId" placeholder="设备类型">
          <el-option v-for="item in DeviceTypeOptions" :key="item.deviceTypeId" :label="item.name" :value="item.deviceTypeId"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <Timerpick :vModel="date" startPlaceholder="开始时间" endPlaceholder="结束时间" :clearable="false" :editable="false" type="date" format="yyyy-MM-dd" />
      </el-col>
      <el-col :span="2" class="btns">
        <el-button icon="el-icon-search" @click="seach">查询</el-button>
      </el-col>
    </el-row>
    <div class="tagtitle" v-if="interfanceTagList.length || deviceTagList.length">{{ CompareOptionsValueText }}</div>
    <div class="deviceList" v-if="interfanceTagList.length || deviceTagList.length">
      <div class="devicetag" v-for="item in interfanceTagList" :key="item.factoryStructId" @click="closeTag(item)">{{ item.label }}</div>
      <div class="devicetag" v-for="item in deviceTagList" :key="item.deviceId" @click="closedeviceTag(item)">{{ item.label }}</div>
      <!-- <el-tag type="info" style="margin-right: 10px" v-for="item in interfanceTagList" :key="item.factoryStructId" closable @close="closeTag(item)">{{ item.label }}</el-tag> -->
      <!-- <el-tag type="info" style="margin-right: 10px" v-for="item in deviceTagList" :key="item.deviceId" closable @close="closedeviceTag(item)">{{ item.label }}</el-tag> -->
    </div>
    <baseSection name="报警排名">
      <el-row>
        <el-col :offset="21" :span="3">
          <div class="switchBox" v-if="isCut">
            <span :title="title[0]" @click="change(1)" :class="ishistogram ? 'histogramactive' : 'histogram'"></span>
            <span :title="title[1]" @click="change(2)" :class="isline ? 'lineactive' : 'line'"></span>
            <span :title="title[2]" @click="change(3)" :class="iscurve ? 'curveactive' : 'curve'"></span>
          </div>
        </el-col>
      </el-row>
      <!-- 次数 -->
      <div class="charts" v-if="isResult">
        <Histogram :ChartsData="ChartsDataNumber" v-if="ishistogram" />
        <Linecharts :ChartsData="ChartsDataNumber" v-if="isline" />
        <Curve :ChartsData="ChartsDataNumber" v-if="iscurve" />
        <!-- 时长 -->
        <Histogram :type="`time`" :ChartsData="ChartsDatatime" v-if="ishistogram" />
        <Linecharts :type="`time`" :ChartsData="ChartsDatatime" v-if="isline" />
        <Curve :type="`time`" :ChartsData="ChartsDatatime" v-if="iscurve" />
      </div>
    </baseSection>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import Timerpick from '@/components/TimerPick/index.vue';
import Histogram from './charts/histogram.vue';
import Linecharts from './charts/line.vue';
import Curve from './charts/curve.vue';
import domtoimage from 'dom-to-image';
import { frontOneHour } from '../until.js';
export default {
  components: {
    Timerpick,
    Histogram,
    Linecharts,
    Curve,
    baseSection
  },
  data() {
    return {
      lineTree: [],
      lineValue: [],
      Props: {
        label: 'name',
        value: 'id',
        children: 'children',
        expandTrigger: 'hover',
        checkStrictly: true,
        multiple: true,
        disabled: 'disabled'
      },
      updateFactory: true,
      levels: true,
      CompareOptionsValue: '1',
      CompareOptionsValueText: '',
      CompareOptions: [
        {
          value: '1',
          label: '设备类型'
        },
        {
          value: '2',
          label: '工厂'
        },
        {
          value: '3',
          label: '车间'
        },
        {
          value: '4',
          label: '产线'
        },
        {
          value: '5',
          label: '设备'
        }
      ],
      DeviceOptions: [],
      deviceId: [],
      DeviceTypeOptions: [],
      isResult: false,
      deviceTypeId: [],
      paramsOptions: [],
      paramsId: '',
      date: [frontOneHour('yyyy-MM-dd', false), frontOneHour('yyyy-MM-dd', true)],
      type: '',
      ishistogram: false,
      isline: false,
      iscurve: false,
      ChartsData: null,
      isdeviceType: false,
      isfactory: false,
      interfanceTagList: [],
      deviceTagList: [],
      Nav_name: 'DEVICE_OEE_DESC',
      ChartsDataNumber: null,
      ChartsDatatime: null,
      isCut: false,
      bugTimerres: null,
      bugNumberres: null,
      Nav_Array: [
        {
          label: '设备效率',
          name: 'DEVICE_OEE_DESC'
        },
        {
          label: '运行时长',
          name: 'DEVICE_RUNNING_DESC'
        },
        {
          label: '待机时长',
          name: 'DEVICE_FREE_DESC'
        },
        {
          label: '报警时长',
          name: 'DEVICE_ALARM_DESC'
        },
        {
          label: '离线时长',
          name: 'DEVICE_OFFLINE_DESC'
        }
      ],
      title: ['柱状图', '曲线图', '曲线图'],
      resData: null
    };
  },
  created() {
    this.init();
  },
  watch: {},
  methods: {
    serDisabled(a) {
      let newLINE = JSON.parse(JSON.stringify(this.lineTree));
      newLINE.forEach(item => {
        item.disabled = a === '2' ? false : a === '5' ? false : true;
        if (item.children) {
          item.children.forEach(ele => {
            ele.disabled = a === '3' ? false : a === '5' ? false : true;
            if (ele.children) {
              ele.children.forEach(e => {
                e.disabled = a === '4' ? false : a === '5' ? false : true;
              });
            }
          });
        }
      });
      this.lineTree = newLINE;
    },
    CompareOptionsValueChange() {
      this.updateFactory = false;
      this.Props.multiple = true;
      this.Props.checkStrictly = true;
      this.levels = true;
      switch (this.CompareOptionsValue) {
        case '1':
          this.CompareOptionsValueText = '设备类型';
          break;
        case '2':
          this.CompareOptionsValueText = '工厂';
          this.serDisabled('2');
          break;
        case '3':
          this.CompareOptionsValueText = '车间';
          this.serDisabled('3');
          break;
        case '4':
          this.CompareOptionsValueText = '产线';
          this.serDisabled('4');
          break;
        case '5':
          this.CompareOptionsValueText = '设备';
          this.Props.multiple = false;
          this.Props.checkStrictly = false;
          this.levels = false;
          this.serDisabled('5');
          break;
      }
      this.interfanceTagList.length = 0;
      this.lineValue.length = 0;
      this.deviceTagList.length = 0;
      this.updateFactory = true;
    },
    clearDeviceType() {
      this.deviceTypeId.length = 0;
    },
    exportToImg() {
      if (!this.ChartsDatatime && !this.ChartsDataNumber) {
        this.$message.warning('暂无数据，无法导出');
        return;
      }
      domtoimage
        .toPng(document.querySelector('.charts'))
        .then(function (dataUrl) {
          let img = new Image();
          img.src = dataUrl;
          let a = document.createElement('a');
          a.setAttribute('href', dataUrl);
          a.setAttribute('download', '故障排名.png');
          a.click();
        })
        .catch(() => {
          this.$message.warning('无数据无法导出');
        });
    },
    async init() {
      const treeRes = (await this.$apis.FactoryChart.list()).map(item => {
        return {
          ...item,
          label: item.name
        };
      });
      this.lineTree = treeRes;
      const deviceTypeRes = await this.$apis.deviceTypeVirtual.deviceType();
      this.DeviceTypeOptions = deviceTypeRes;
      this.deviceTypeId.push(this.DeviceTypeOptions[0].deviceTypeId);
      this.DeivceTypehandleChange();
      this.seach();
    },
    async LinehandleChange() {
      this.isdeviceType = true;

      if (this.lineValue.length === 0) {
        this.interfanceTagList = [];
        this.isdeviceType = false;
        return false;
      }
      let label;

      // 判断是否选择新产线去获取设备
      let newLineValue = JSON.parse(JSON.stringify(this.lineValue));
      newLineValue.forEach(item => {
        if (item.length === 3) {
          let interfanceTagList = JSON.parse(JSON.stringify(this.interfanceTagList));
          interfanceTagList.forEach(ele => {
            if (ele.nodeType === 'line' && ele.factoryStructId === item[2]) {
              item[2] = 0;
            }
          });
        }
      });
      this.interfanceTagList = [];
      // nodetype唯一标识
      this.lineValue.forEach(item => {
        switch (item.length) {
          case 1:
            this.lineTree.forEach(ele => {
              if (item[0] === ele.id) {
                label = ele.name;
                this.interfanceTagList.push({ label, nodeType: 'factoryWork', factoryStructId: item[0] });
              }
            });
            return false;
          case 2:
            this.lineTree.forEach(ele => {
              if (ele.children) {
                ele.children.forEach(element => {
                  if (item[1] === element.id) {
                    label = element.name;
                    this.interfanceTagList.push({ label, nodeType: 'workShop', factoryStructId: item[1] });
                  }
                });
              }
            });
            return false;
          case 3:
            this.lineTree.forEach(ele => {
              if (ele.children) {
                ele.children.forEach(element => {
                  if (element.children) {
                    element.children.forEach(e => {
                      if (item[2] === e.id) {
                        label = e.name;
                        this.interfanceTagList.push({ label, nodeType: 'line', factoryStructId: item[2], lineId: item[2] });
                      }
                    });
                  }
                });
              }
            });
            break;
        }
      });
      let newlineId;
      if (this.CompareOptionsValue === '5' && this.lineValue.length === 3) {
        newlineId = this.lineValue[2];
      } else {
        return false;
      }

      this.DeviceOptions = [];
      this.deviceId = '';
      const data = {
        path: newlineId,
        currentPageSize: 500
      };
      const deviceRes = await this.$apis.LSDconfig.getdevice(data);
      this.DeviceOptions = deviceRes;
      if (this.deviceId === '' && this.lineValue.length === 0) {
        this.isdeviceType = false;
      } else {
        this.isdeviceType = true;
      }

      // 添加到下方Tag中
    },
    async DeivcehandleChange() {
      this.DeviceOptions.forEach(item => {
        this.deviceId.forEach(ele => {
          if (item.id === ele) {
            this.deviceTagList.push({ label: item.name, deviceIds: item.id, nodeType: 'device' });
          }
        });
      });
      function unique(arr) {
        let obj = {};
        return arr.filter(item => {
          // 防止key重复
          let newItem = item + JSON.stringify(item);
          // eslint-disable-next-line no-prototype-builtins
          return obj.hasOwnProperty(newItem) ? false : (obj[newItem] = true);
        });
      }

      this.deviceTagList = unique(this.deviceTagList);
      if (this.lineValue.length === 0 && this.deviceId === '') {
        this.isdeviceType = false;
      }
    },
    clearDevice() {
      if (this.lineValue.length === 0) {
        this.isdeviceType = false;
      }
    },
    DeivceTypehandleChange() {
      let label;
      this.interfanceTagList = [];
      this.deviceTypeId.forEach(item => {
        this.DeviceTypeOptions.forEach(ele => {
          if (item === ele.deviceTypeId) {
            label = ele.name;
            this.interfanceTagList.push({ label, deviceTypeId: this.deviceTypeId, nodeType: 'deviceType' });
          }
        });
      });
      this.isfactory = true;
    },
    closeTag(data) {
      this.isfactory = true;
      if (this.isdeviceType) {
        this.interfanceTagList.forEach((item, index) => {
          if (item.factoryStructId === data.factoryStructId) {
            this.interfanceTagList.splice(index, 1);
          }
        });
      } else {
        this.interfanceTagList.forEach((item, index) => {
          if (item.label === data.label) {
            this.interfanceTagList.splice(index, 1);
          }
        });
      }
      if (this.deviceTagList.length === 0 && this.interfanceTagList.length === 0) {
        this.isfactory = false;
        this.isdeviceType = false;
        this.isCut = false;
        this.ChartsDatatime = null;
        this.ChartsDataNumber = null;
        this.isResult = false;
        this.lineValue = [];
        this.deviceTypeId = [];
        this.deviceId = [];
      }
    },
    closedeviceTag(data) {
      this.isfactory = true;
      this.deviceTagList.forEach((item, index) => {
        if (item.deviceIds === data.deviceIds) {
          this.deviceTagList.splice(index, 1);
        }
      });
      if (this.deviceTagList.length === 0 && this.interfanceTagList.length === 0) {
        this.isfactory = false;
        this.isdeviceType = false;
        this.lineValue = [];
        this.deviceTypeId = [];
        this.deviceId = [];
      }
    },
    async seach() {
      let data;
      this.isResult = false;
      this.ishistogram = true;
      this.isline = false;
      this.iscurve = false;
      let deviceTypeIds = [];
      let factoryStructIds = [];
      if (this.interfanceTagList.length !== 0 && this.deviceTagList.length !== 0) {
        this.$message.warning('仅相同模块可对比');
        return false;
      }
      if (this.interfanceTagList) {
        let isOpen = false;
        this.interfanceTagList.forEach(item => {
          this.interfanceTagList.forEach(ele => {
            if (item.nodeType !== ele.nodeType) {
              isOpen = true;
            } else if (item.nodeType === 'deviceType') {
              deviceTypeIds.push(item.deviceTypeId);
            } else {
              factoryStructIds.push(item.factoryStructId);
            }
          });
        });
        deviceTypeIds = this.unique(deviceTypeIds);
        factoryStructIds = this.unique(factoryStructIds);
        data = {
          factoryStructIds,
          deviceTypeIds: deviceTypeIds[0],
          startDate: this.date[0],
          endDate: this.date[1]
        };
        if (isOpen) {
          this.$message.warning('仅相同模块可对比');
          return false;
        }
      }

      if (this.interfanceTagList.length === 1 && this.interfanceTagList[0].nodeType === 'line') {
        data = {
          lineIds: [this.interfanceTagList[0].lineId],
          startDate: this.date[0],
          endDate: this.date[1]
        };
      }
      if (this.deviceTagList.length >= 1) {
        let deviceIds = [];
        this.deviceTagList.forEach(item => {
          deviceIds.push(item.deviceIds);
        });
        data = {
          deviceIds,
          startDate: this.date[0],
          endDate: this.date[1]
        };
      }
      if (this.date.length !== 2) {
        this.$message.warning('未选择查询时间段');
        return false;
      }
      // this.$message.success('success');
      const bugNumberres = await this.$apis.Bugcharts.bugNumber(data);
      const bugTimerres = await this.$apis.Bugcharts.bugTime(data);
      this.ChartsDataNumber = bugNumberres;
      this.ChartsDataNumber.color = '#f39800';
      this.ChartsDataNumber.bordercolor = '#e5ad40';
      this.ChartsDataNumber.title = ['次数'];
      this.ChartsDatatime = bugTimerres;
      this.ChartsDatatime.color = '#ea3837';
      this.ChartsDatatime.bordercolor = '#eb8d8f';
      this.ChartsDatatime.title = ['时长'];
      for (let i = 0; i < this.ChartsDatatime.yaxisValues.length; i++) {
        this.ChartsDatatime.yaxisValues[i] = (this.ChartsDatatime.yaxisValues[i] / 1000 / 60).toFixed(2);
      }
      this.ishistogram = true;
      this.isCut = true;
      this.isResult = true;
      // this.resData = data;
    },
    unique(arr) {
      let newArr = [];
      for (let i = 0; i < arr.length; i++) {
        if (newArr.indexOf(arr[i]) === -1) {
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    change(data) {
      switch (data) {
        case 1:
          this.ishistogram = true;
          this.isline = false;
          this.iscurve = false;
          break;
        case 2:
          this.ishistogram = false;
          this.isline = true;
          this.iscurve = false;
          break;
        case 3:
          this.ishistogram = false;
          this.isline = false;
          this.iscurve = true;
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
}
.iconfont {
  line-height: 11px;
}
.switchBox {
  display: flex;
  justify-content: space-around;
  width: 150px;
}
.histogram {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts01.png');
}
.histogramactive {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts01active.png');
}
.line {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts02.png');
}
.lineactive {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts02active.png');
}
.curve {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts03.png');
}
.curveactive {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts03active.png');
}
.deviceList {
  margin-bottom: 10px;
  border: 1px solid #dcdcdc;
}
.device_nav {
  width: 600px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  display: flex;
}
.nav_item {
  flex: 1;
  text-align: center;
  border-right: 1px solid #f3f3f3;
  cursor: pointer;
}
.nav_active {
  border-radius: 5px;
  border: 1px solid #f39800;
  background-color: #fffce7;
  cursor: pointer;
}
.device_detailPage {
  height: 1000px;
}
.tagtitle {
  font-size: 16px;
  margin-bottom: 10px;
}
.el-cascader,
.el-select {
  width: 100%;
}
/deep/ .time-slelector {
  display: flex;
  .el-input {
    flex: 1;
    &:nth-child(1) {
      margin-right: 3%;
    }
  }
}
.btns {
  display: flex;
  .el-button {
    width: 92px;
    height: 36px;
  }
}
.deviceList {
  display: flex;
  flex-wrap: wrap;
  width: 630px;
  margin-bottom: 10px;
  padding: 18px 20px;
  border-radius: 4px;
}
.devicetag {
  position: relative;
  margin-right: 14px;
  padding: 10px 8px;
  margin-bottom: 10px;
  background-color: #f3f3f3;
  border-radius: 4px;
  color: #909399;
  cursor: pointer;
}
.devicetag:hover {
  background-color: rgba(243, 152, 0, 0.1);
  color: #f39800;
  cursor: pointer;
}
.devicetag:hover::after {
  content: 'x';
  position: absolute;
  width: 12px;
  height: 12px;
  font-size: 10px;
  line-height: 10px;
  border-radius: 50%;
  text-align: center;
  background-color: #f39800;
  color: #fff;
  top: -4px;
  right: -4px;
}
/deep/ .el-cascader__tags {
  flex-wrap: nowrap !important;
  // overflow: hidden;
}
</style>
